export const projects = [
	{
		id: 1,
		name: "Promotional Email",
		desc: "A promotional email template created using HTML, Sass, and Foundation Email Framework.",
		stack: ["HTML", "Sass", "Foundation Email Framework"],
		github: "https://github.com/theruelmisa/workspace_emails",
		website: "https://workspace-promotional.netlify.app/",
		image: "https://i.imgur.com/R0DKzpD.png",
		start: "left",
	},
	{
		id: 2,
		name: "Transactional Email",
		desc: "A transactional email template created using HTML, Sass, and Foundation Email Framework.",
		stack: ["HTML", "Sass", "Foundation Email Framework"],
		github: "https://github.com/theruelmisa/workspace_emails",
		website: "https://workspace-transactional.netlify.app/",
		image: "https://i.imgur.com/NSI1ckA.png",
		start: "right",
	},
	{
		id: 3,
		name: "Newsletter Email",
		desc: "A newsletter email template created using HTML, Sass, and Foundation Email Framework.",
		stack: ["HTML", "Sass", "Foundation Email Framework"],
		github: "https://github.com/theruelmisa/workspace_emails",
		website: "https://workspace-newsletter.netlify.app/",
		image: "https://i.imgur.com/tfYn7JD.png",
		start: "left",
	},
	{
		id: 4,
		name: "Medusa",
		desc: "A landing page theme designed for a subscription service. Just a simple and clean design.",
		stack: ["HTML", "CSS"],
		github: "https://github.com/theruelmisa/medusa-landing-page",
		website: "https://medusa-systems.netlify.app/ ",
		image: "https://i.imgur.com/wZRC25o.png",
		start: "right",
	},
	{
		id: 5,
		name: "Beast Fit",
		desc: "A landing page theme designed for a fitness center. Displays a simple page transition. ",
		stack: ["HTML", "Sass"],
		github: "https://github.com/theruelmisa/beastFit",
		website: "https://beastfit.netlify.app/",
		image: "https://i.imgur.com/Ik8jMKL.png",
		start: "left",
	},

	{
		id: 6,
		name: "Building Scout",
		desc: "A landing page theme designed for any business that showcases multiple images. Includes basic jQuery code for the header.",
		stack: ["HTML", "CSS", "jQuery"],
		github: "https://github.com/theruelmisa/building-scout",
		website: "https://buildingscout.netlify.app/",
		image: "https://i.imgur.com/d7sxfhs.png",
		start: "right",
	},
];

// {
// 	id: 4,
// 	name: "Home Salon",
// 	desc: "A landing page theme designed for a small business that does at-your-home services. Contains multiple transitions.",
// 	stack: ["HTML", "Sass"],
// 	github: "https://github.com/theruelmisa/home-salon",
// 	website: "https://salonathome.netlify.app/",
// 	image: "https://i.imgur.com/3H75Tdk.png",
// 	start: "right",
// },
